<template>
  <div class="user">
    <search-header :isSnShow="false"></search-header>
    <div class="container pt-3 pb-3 p-0">
      <div class="row p-0">
        <div class="col-2">
          <user-left :currentActive="7"></user-left>
        </div>
        <div class="col-10">
          <div class="row bg-white rounded ml-2">
            <div class="p-3 border-bottom col-12">
              <h6>更换手机</h6>
            </div>
            <div class="col-12 mb-4">
                <div v-if="tips != ''" class="alert alert-danger p-1 mt-2 d-flex align-items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-lightbulb" viewBox="0 0 16 16">
                        <path
                            d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13a.5.5 0 0 1 0 1 .5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1 0-1 .5.5 0 0 1 0-1 .5.5 0 0 1-.46-.302l-.761-1.77a1.964 1.964 0 0 0-.453-.618A5.984 5.984 0 0 1 2 6zm6-5a5 5 0 0 0-3.479 8.592c.263.254.514.564.676.941L5.83 12h4.342l.632-1.467c.162-.377.413-.687.676-.941A5 5 0 0 0 8 1z" />
                    </svg>
                    <span class="ml-1 mt-1">{{tips}}</span>
                </div>

              <div class="clearfix mt-3">
                <div class="float-left text-right mr-3 mt-2 width-100">
                  <span>原手机号码</span>
                </div>
                <div class="float-left">
                  <input value="177****9953" placeholder="请填写手机号码" readonly class="form-control mr-sm-2 width-300" maxlength="20" type="text"/>
                </div>
              </div>

              <div class="clearfix mt-3">
                <div class="float-left text-right mr-3 mt-2 width-100">
                  <span>短信验证码</span>
                </div>
                <div class="float-left">
                 <div class="clearfix">
                    <div class="float-left">
                        <b-form-input type="text" class="rounded width-186" v-model="text" placeholder="填写动态码"
                            maxlength='20' autocomplete='off'></b-form-input>
                    </div>
                    <div class="float-left ml-2">
                        <b-button class="w-100" variant="success">获取动态码</b-button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="clearfix mt-3">
                <div class="float-left text-right mr-3 mt-2 width-100">
                  <span>新手机号码</span>
                </div>
                <div class="float-left">
                  <input v-model="trueName" placeholder="请填写手机号码" class="form-control mr-sm-2 width-300" maxlength="20" type="text"/>
                </div>
              </div>

              <div class="clearfix mt-3">
                <div class="float-left text-right mr-3 mt-2 width-100">
                  <span>短信验证码</span>
                </div>
                <div class="float-left">
                 <div class="clearfix">
                    <div class="float-left">
                        <b-form-input type="text" class="rounded width-186" v-model="text" placeholder="填写动态码"
                            maxlength='20' autocomplete='off'></b-form-input>
                    </div>
                    <div class="float-left ml-2">
                        <b-button class="w-100" variant="success">获取动态码</b-button>
                    </div>
                  </div>
                </div>
              </div>
              
              <div class="clearfix mt-3">
                <b-button
				          @click="applyCertification"
                  variant="success"
                  style="width:186px;margin-left: 116px">确定提交</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <map-footer :isMapShow="false"></map-footer>
  </div>
</template>

<script>
export default {
  name: "mobileupdate",
  data() {
    return {
      tips:'',
    };
  },
  methods: {
  },
  created() {
  },
};
</script>

<style scoped>
@import url("~@/assets/css/user.css");
</style>
